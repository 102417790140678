<template>
  <div>
      <my-navbar></my-navbar>
      <div class="my-content">
          <!-- <transition name="fade"> -->
            <router-view></router-view>
          <!-- </transition> -->
      </div>
      <my-footer></my-footer>
  </div>
</template>

<script>
import myNavbar  from '../layout/navbar.vue'
import myFooter from '../layout/footer.vue'

export default {
    components : { myNavbar,myFooter },
    data(){
        return{
            
            
        }
    },
    created(){
        
    },
    methods:{
        
    }
}
</script>

<style>
.nav-link{
    color: white !important;
}
.my-nav-link{
    text-decoration: underline  !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>